// extracted by mini-css-extract-plugin
export var AboutSectionContainer = "sectionLayout-module--AboutSectionContainer--48ebe";
export var BannerVideo = "sectionLayout-module--BannerVideo--e37fd";
export var CircleDescriptionTitle = "sectionLayout-module--CircleDescriptionTitle--19854";
export var DesginationCompany = "sectionLayout-module--DesginationCompany--4c702";
export var DesginationContainer = "sectionLayout-module--DesginationContainer--6f3fa";
export var DesginationDescription = "sectionLayout-module--DesginationDescription--ceb19";
export var DesginationName = "sectionLayout-module--DesginationName--b3245";
export var DesginationTitle = "sectionLayout-module--DesginationTitle--18ff3";
export var MisionVisonTitleDesc = "sectionLayout-module--MisionVisonTitleDesc--33ffe";
export var PlatformSectionContainer = "sectionLayout-module--PlatformSectionContainer--f4337";
export var PlatformTitleDesc = "sectionLayout-module--PlatformTitleDesc--0a196";
export var ScheduleADemo = "sectionLayout-module--ScheduleADemo--b2b3e";
export var ScheduleADemoDesc = "sectionLayout-module--ScheduleADemoDesc--759f2";
export var ScheduleADemoForm = "sectionLayout-module--ScheduleADemoForm--27e14";
export var ScheduleADemoHeading = "sectionLayout-module--ScheduleADemoHeading--4d499";
export var ScheduleADemoThankyouText = "sectionLayout-module--ScheduleADemoThankyouText--cf421";
export var ScheduleADemoWelcomeText = "sectionLayout-module--ScheduleADemoWelcomeText--f581e";
export var SecondaryCircleCardLayout = "sectionLayout-module--SecondaryCircleCardLayout--eb18b";
export var SecondarySquareCardLayout = "sectionLayout-module--SecondarySquareCardLayout--68929";
export var SecondarySquareCardLayoutContent = "sectionLayout-module--SecondarySquareCardLayoutContent--ebc6b";
export var SecondarySquareCardLayoutSecondaryText = "sectionLayout-module--SecondarySquareCardLayoutSecondaryText--c0ae2";
export var UseCaseSectionContainer = "sectionLayout-module--UseCaseSectionContainer--d8461";
export var bgVideo = "sectionLayout-module--bgVideo--47a03";
export var blackText = "sectionLayout-module--blackText--3bb5b";
export var careersContainer = "sectionLayout-module--careersContainer--273d7";
export var clientsDescription = "sectionLayout-module--clientsDescription--c2722";
export var clientsNameDesignation = "sectionLayout-module--clientsNameDesignation--e4cdd";
export var connectH2 = "sectionLayout-module--connectH2--581a9";
export var container = "sectionLayout-module--container--61461";
export var contentBox = "sectionLayout-module--contentBox--cf796";
export var demoButton = "sectionLayout-module--demoButton--d4e2f";
export var description = "sectionLayout-module--description--1649b";
export var descriptionPlatform = "sectionLayout-module--descriptionPlatform--19596";
export var descriptionText = "sectionLayout-module--descriptionText--1c6d6";
export var emailInputContainer = "sectionLayout-module--emailInputContainer--50b11";
export var embedVideosContainer = "sectionLayout-module--embedVideosContainer--fe7b4";
export var fadeIn = "sectionLayout-module--fadeIn--d9d9e";
export var fadeOut = "sectionLayout-module--fadeOut--2370f";
export var formSubmitSuccess = "sectionLayout-module--formSubmitSuccess--b1dd0";
export var heading = "sectionLayout-module--heading--488ee";
export var headingContainer = "sectionLayout-module--headingContainer--1be2a";
export var kpointPopupLogo = "sectionLayout-module--kpointPopupLogo--f8f69";
export var lifeatkpointContainer = "sectionLayout-module--lifeatkpointContainer--10d27";
export var minutes = "sectionLayout-module--minutes--cc33b";
export var mystoryContainer = "sectionLayout-module--mystoryContainer--a4238";
export var peopleCardLayout = "sectionLayout-module--peopleCardLayout--d6fa5";
export var peopleContainer = "sectionLayout-module--peopleContainer--ae3fd";
export var queryText = "sectionLayout-module--queryText--8080c";
export var shareContainer = "sectionLayout-module--shareContainer--4c845";
export var shareDivider = "sectionLayout-module--shareDivider--331d7";
export var shareSocialContainer = "sectionLayout-module--shareSocialContainer--f7a52";
export var shareSocialLink = "sectionLayout-module--shareSocialLink--6b74c";
export var shareSocialLinkImg = "sectionLayout-module--shareSocialLinkImg--b2679";
export var shareTitle = "sectionLayout-module--shareTitle--5fcec";
export var successMessageBox = "sectionLayout-module--successMessageBox--01066";
export var useCaseTitleDesc = "sectionLayout-module--useCaseTitleDesc--99584";
export var videoContainer = "sectionLayout-module--videoContainer--ac1ba";
export var whiteText = "sectionLayout-module--whiteText--763be";