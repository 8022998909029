import React, { useEffect, useState } from "react";
import { ScheduleADemo, Share } from "./layoutSections/LayoutSection";
import ContactForm from "../components/forms/ContactForm";
import { RenderSvg, ScreenSize } from "../utils/helpers/helper";
export const Popup = ({
  openPopup,
  setOpenPopup,
  scheduleDemo,
  header,
  IsEmbedded,
  isMobile,
  isVertical,
  contact,
  shareLink,
  share,
}) => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(ScreenSize.isLargeScreen());
    };

    checkScreenSize();
  }, []);

  return (
    <>
      <div
        className={`popup-overlay`}
        style={{
          opacity: openPopup ? "1" : "0",
          zIndex: openPopup ? "9999" : "-9999",
        }}
      >
        <div
          className={`popup-overlay-inner`}
          style={
            header
              ? {
                  width: isMobile ? "100%" : isLargeScreen ? "340px" : "300px",
                  marginTop: isMobile ? (openPopup ? "0" : "-100%") : "auto",
                  marginInline: isMobile ? "0" : "auto",
                  borderRadius: isMobile
                    ? openPopup
                      ? "0 0 22px 22px"
                      : "22px"
                    : "22px",
                  height: isLargeScreen
                    ? "600px"
                    : isVertical
                    ? "500px"
                    : "430px",

                  opacity: openPopup ? "1" : "0",
                }
              : {
                  opacity: openPopup ? "1" : "0",
                  width: share
                    ? "fit-content"
                    : contact
                    ? isMobile
                      ? "90%"
                      : "40vw"
                    : "",
                  ...{ height: contact && isMobile ? "max-content" : {} },
                }
          }
        >
          {scheduleDemo && (
            <ScheduleADemo
              header={header}
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              isMobile={isMobile}
            />
          )}
          {share && <Share link={shareLink} />}
          {contact && (
            <ContactForm
              heading={"Contact Form"}
              isMobile={isMobile}
              header={header}
              isVertical={isVertical}
              handelContactForm={setOpenPopup}
              openPopup={openPopup}
            />
          )}
          {IsEmbedded ? (
            <></>
          ) : (
            <button
              className={`closeButton ${header ? "headerCloseButton" : ""}`}
              onClick={() => setOpenPopup(null)}
            >
              <RenderSvg
                IconName="times"
                IconType="light"
                style={{ height: "14px" }}
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
};
