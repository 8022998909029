import React, { useRef, useEffect, useState, useCallback } from "react";
import { RenderSvg } from "../utils/helpers/helper";
import { ScreenSize } from "../utils/helpers/helper";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

export const ProductsPopup = ({
  openProductsPopup,
  setOpenProductsPopup,
  header,
  isMobile,
  contact,
  share,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "productLogos" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  // Extracting image nodes
  const images = data.allFile.edges;
  const findImageByRelativePath = (relativePath) => {
    const imageNode = images.find(
      (image) => image.node.relativePath === relativePath
    ).node;
    return getImage(imageNode);
  };

  const popupRef = useRef(null);
  const excludedRef = useRef(null);
  const [hoveredProduct, setHoveredProduct] = useState(null);

  const productLinks = [
    {
      id: "tube",
      title: "One Video Platform. All Videos",
      image: "productLogos/tubeMiniLogo.png",
      link: "/products/tube",
      isExternal: false,
    },
    {
      id: "xpresso",
      title: "An Enterprise social video experiences app",

      image: "productLogos/xpressoMiniLogo.png",
      link: "/products/xpresso",
      isExternal: false,
    },
    {
      id: "play",
      title: "DIY interactivity for videos",
      image: "productLogos/playMiniLogo.png",
      link: "/products/play",
      isExternal: false,
    },
    {
      id: "fab",
      title: "Social experiences for your digital channels",
      image: "productLogos/fabMiniLogo.png",
      link: "https://www.getfab.app/",
      isExternal: true,
    },
    {
      id: "koel",
      title: "The multilingual AI voice personalization tool for Enterprises",
      image: "productLogos/koelMiniLogo.png",
      link: "/products/koel",
      isExternal: false,
    },
  ];

  const closePopup = useCallback(() => {
    setOpenProductsPopup(null);
  }, [setOpenProductsPopup]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !excludedRef.current?.contains(event.target)
      ) {
        closePopup();
      }
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        closePopup();
      }
    };

    if (openProductsPopup) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [openProductsPopup, closePopup]);

  if (!openProductsPopup) return null;

  return (
    <div
      className={`popup-overlay`}
      style={{
        opacity: "1",
        zIndex: "9999",
      }}
    >
      <div
        ref={popupRef}
        className={`popup-overlay-inner`}
        style={
          header
            ? {
                width: "100%",
                maxWidth: isMobile ? "100%" : "600px",
                marginTop: "50px",
                marginLeft: "auto",
                borderRadius: "10px",
                height: "auto",
                background: "transparent",
                opacity: "1",
                display: "flex",
                flexDirection: "column",
                gap: "0.6rem",
                justifyContent: "flex-start",
                padding: "1rem",
              }
            : {
                opacity: "1",
                width: share
                  ? "fit-content"
                  : contact
                  ? isMobile
                    ? "90%"
                    : "40vw"
                  : "",
                height: contact && isMobile ? "max-content" : undefined,
              }
        }
      >
        {productLinks.map((product) =>
          product.isExternal ? (
            <a
              key={product.id}
              href={product.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={closePopup}
              onMouseEnter={() => setHoveredProduct(product.id)}
              onMouseLeave={() => setHoveredProduct(null)}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "flex",
                padding: "0 10px",
                borderRadius: "10px",
                background: "rgba(255,255,255,0.8)",
                height: "70px",
                width: "100%",
                gap: "2rem",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <div
                style={{ height: "50px", width: "50px", objectFit: "contain" }}
              >
                <GatsbyImage
                  image={findImageByRelativePath(product?.image)}
                  alt={product?.id || "img"}
                />
              </div>

              <span
                style={{
                  textAlign: "flex-end",
                  fontSize: isMobile ? "12px" : "14px",

                  fontWeight: "600",
                }}
              >
                {product.title}
              </span>
              {hoveredProduct === product.id && ScreenSize.isDesktop() && (
                <RenderSvg
                  style={{ position: "absolute", right: "6%" }}
                  IconName="arrow-circle-right"
                  IconType="light"
                />
              )}
            </a>
          ) : (
            <Link
              key={product.id}
              to={product.link}
              onClick={closePopup}
              onMouseEnter={() => setHoveredProduct(product.id)}
              onMouseLeave={() => setHoveredProduct(null)}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "flex",
                padding: "0 10px",
                borderRadius: "10px",
                background: "rgba(255,255,255,0.8)",
                height: "70px",
                width: "100%",
                gap: "2rem",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <div
                style={{ height: "50px", width: "50px", objectFit: "contain" }}
              >
                <GatsbyImage
                  image={findImageByRelativePath(product?.image)}
                  alt={product?.id || "img"}
                />
              </div>

              <span
                style={{
                  fontSize: isMobile ? "12px" : "14px",

                  fontWeight: "600",
                }}
              >
                {product.title}
              </span>

              {hoveredProduct === product.id && ScreenSize.isDesktop() && (
                <RenderSvg
                  style={{ position: "absolute", right: "6%" }}
                  IconName="arrow-circle-right"
                  IconType="light"
                />
              )}
            </Link>
          )
        )}
      </div>
    </div>
  );
};
