// extracted by mini-css-extract-plugin
export var anchorLink = "newFooter-module--anchorLink--1fb0f";
export var anchorLinkCity = "newFooter-module--anchorLinkCity--f50e9";
export var bottomContent = "newFooter-module--bottomContent--3de2a";
export var city = "newFooter-module--city--f4ebb";
export var cityContainer = "newFooter-module--cityContainer--5f2a9";
export var citySubcontainer = "newFooter-module--citySubcontainer--13d53";
export var copyRight = "newFooter-module--copyRight--6e321";
export var disabledAnchorLink = "newFooter-module--disabledAnchorLink--6e031";
export var disabledHeading = "newFooter-module--disabledHeading--ed7d3";
export var disabledLink = "newFooter-module--disabledLink--af3eb";
export var dropDownHeading = "newFooter-module--dropDownHeading--4d2a7";
export var dropDownList = "newFooter-module--dropDownList--5ccab";
export var dropDownListUl = "newFooter-module--dropDownListUl--ce301";
export var dropDownTab = "newFooter-module--dropDownTab--3adca";
export var footer = "newFooter-module--footer--1b2b2";
export var footerDiv = "newFooter-module--footerDiv--44860";
export var footerGroup = "newFooter-module--footerGroup--d8591";
export var footerGroup0 = "newFooter-module--footerGroup0--2225a";
export var footerGroup1 = "newFooter-module--footerGroup1--d1408";
export var footerGroup2 = "newFooter-module--footerGroup2--8ccd3";
export var footerGroup3 = "newFooter-module--footerGroup3--88f6c";
export var footerGroup4 = "newFooter-module--footerGroup4--f53e4";
export var footerGroup5 = "newFooter-module--footerGroup5--52365";
export var footerLinksIcons = "newFooter-module--footerLinksIcons--d5ed1";
export var footerLogo = "newFooter-module--footerLogo--ebec6";
export var footerSection = "newFooter-module--footerSection--ad36d";
export var group = "newFooter-module--group--8f441";
export var heading = "newFooter-module--heading--09e0d";
export var linksContainer = "newFooter-module--linksContainer--22322";
export var list = "newFooter-module--list--710f5";
export var nonClickable = "newFooter-module--nonClickable--b1cf7";
export var nonClickableContainer = "newFooter-module--nonClickableContainer--2b500";
export var nonClickableLink = "newFooter-module--nonClickableLink--51772";
export var ourPresenceList = "newFooter-module--ourPresenceList--807e1";
export var privacyPolicy = "newFooter-module--privacyPolicy--49d16";