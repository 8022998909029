import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { RenderSvg, ScreenSize, baseUrl } from "../../helpers/helper";
import * as styles from "./css/newFooter.module.css";

const footerData = [
  {
    sections: [
      {
        heading: "Home",
        page: "/",
        section: "#home-banner",
        items: [
          { label: "Our Products", page: "/", section: "#home-product" },
          { label: "Our Impact", page: "/", section: "#home-caseStudy" },
          { label: "Our Success Symbols", page: "/", section: "#home-logos" },
          { label: "Customer Speak", page: "/", section: "#home-customer" },
        ],
      },
      {
        heading: "Products",
        page: "/products/",
        section: "",
        items: [
          { label: "TUBE", page: "/products/", section: "tube" },
          { label: "XPRESSO", page: "/products/", section: "xpresso" },
          { label: "PLAY", page: "/products/", section: "play" },
          { label: "FAB", page: "", section: "" },
          { label: "KOEL", page: "/products/", section: "koel" },
        ],
      },
    ],
  },
  {
    sections: [
      {
        heading: "Use Cases",
        section: "#",
        items: [
          { label: "Pre-Onboarding", disable: true, section: "#footer" },
          {
            label: "Onboarding",
            disable: true,
            section: "#footer",
          },
          { label: "Knowledge Retention", disable: true, section: "#footer" },
          {
            label: "Communication & Compliance",
            disable: true,
            section: "#footer",
          },
          { label: "Team Collaboration", disable: true, section: "#footer" },
          { label: "Employee Development", disable: true, section: "#footer" },
          { label: "Digital Communities", disable: true, section: "#footer" },
          { label: "Tech Adoption", disable: true, section: "#footer" },
          { label: "Field Force Connect", disable: true, section: "#footer" },
          { label: "Lead Generation", disable: true, section: "#footer" },
          { label: "Customer Support", disable: true, section: "#footer" },
          { label: "Retention", disable: true, section: "#footer" },
          { label: "Upsell/Cross-sell", disable: true, section: "#footer" },
          { label: "Customer Education ", disable: true, section: "#footer" },
          { label: "User Engagement", disable: true, section: "#footer" },
          { label: "Employee Education ", disable: true, section: "#footer" },
          { label: "User Awareness", disable: true, section: "#footer" },
        ],
      },
    ],
  },
  {
    sections: [
      {
        heading: "Platform",
        page: "/platform/",
        section: "",
        items: [
          {
            label: "Core Video Platform",
            page: "/platform/",
            section: "#platform-core-video-platform",
          },
          {
            label: "Interactive & Personalized",
            page: "/platform/",
            section: "#platform-personalisation",
          },
          {
            label: "Embeddable Player & SDK ",
            page: "/platform/",
            section: "#platform-embeddable-player-sdk",
          },
          {
            label: "Video Analytics for BI",
            page: "/platform/",
            section: "#platform-Analytics",
          },
          {
            label: "Deep Search & Discovery",
            page: "/platform/",
            section: "#platform-search",
          },
          {
            label: "Content Creation Tools",
            page: "/platform/",
            section: "#platform-content-creation",
          },
          {
            label: "Live Broadcast",
            page: "/platform/",
            section: "#platform-live-stream",
          },
          {
            label: "Entrerprise Portal & CMS",
            page: "/platform/",
            section: "#platform-Enterprise-Portal-CMS",
          },
        ],
      },
      {
        heading: "AI @ KPOINT",
        page: "/ai-at-kpoint/",
        section: "#kai",
        disable: false,
      },
      {
        heading: "Content Enablement",
        page: "/content-enablement/",
        section: "#content-enablement",
        disable: false,
      },
    ],
  },
  {
    sections: [
      {
        heading: "About",
        page: "/about/",
        section: "",
        items: [
          {
            label: "Mission, Vision & Values",
            page: "/about/",
            section: "#about-mission",
          },
          // { label: "Brand Assets", page: "/about/", section: "" },
          { label: "Our Story", page: "/about/", section: "#about-ourStory" },
          {
            label: "The Leadership Team",
            page: "/about/",
            section: "#about-leadershipTeam",
          },
        ],
      },
      {
        heading: "Resources",
        page: "/resources/",
        section: "",
        items: [
          { label: "Blogs", page: "/resources/", section: "blogs/" },
          { label: "Case Study", page: "/resources/", section: "casestudy/" },
          { label: "KPOINT NOW", page: "/resources/", section: "kpoint-now/" },
        ],
      },
      {
        heading: "Careers",
        page: "/careers/",
        section: "",
        items: [
          {
            label: "Our People",
            page: "/careers/",
            section: "#career-ourPeople",
          },
          {
            label: "Life at KPOINT",
            page: "/careers/",
            section: "#life-at-kpoint",
          },
          {
            label: "Current Job Openings",
            page: "/careers/",
            section: "#jobs-container",
          },
        ],
      },
    ],
  },
  {
    sections: [
      { heading: "Contact Us", page: "/contact-us/", section: "" },
      {
        heading: "Head Office",
        section: "#footer",
        items: [
          {
            label:
              "Office No. 201, 2nd Floor, S. R. Iriz Apartments, Baner - Pashan Link Road, Pune - 411021, Maharashtra, India",
            disable: true,
            isAdress: true,
            section: "#footer",
          },
          {
            label: "Ph. no. 020 4852 2626",
            disable: true,
            isAdress: true,
            section: "#footer",
          },
        ],
      },
      {
        heading: "Our Presence",
        section: "",
        disable: true,
        items: [
          { label: "Pune", disable: false, section: "" },
          { label: "Mumbai", disable: true, section: "" },
          { label: "Delhi", disable: true, section: "" },
          { label: "Bengaluru", disable: true, section: "" },
        ],
      },
      // {
      //   heading: "Phone No.",
      //   section: "",
      //   disable: true,
      //   items: [{ label: "020 4852 2626", disable: true }],
      // },
    ],
  },
];

const mobileFooterData = [
  {
    heading: "Home",
    type: "dropDown",
    items: [
      { label: "Our Products", page: "/", section: "#home-product" },
      { label: "Our Impact", page: "/", section: "#home-caseStudy" },
      { label: "Our Success Symbols", page: "/", section: "#home-logos" },
      { label: "Customer Speak", page: "/", section: "#home-customer" },
    ],
  },
  {
    heading: "Products",
    type: "dropDown",
    items: [
      { label: "TUBE", page: "/products/", section: "tube" },
      { label: "XPRESSO", page: "/products/", section: "xpresso" },
      { label: "PLAY", page: "/products/", section: "play" },
      { label: "FAB", page: "", section: "" },
      { label: "KOEL", page: "/products/", section: "koel" },
    ],
  },
  {
    heading: "Use Cases",
    type: "dropDown",
    items: [
      { label: "Pre-Onboarding", disable: true, section: "" },
      {
        label: "Onboarding",
        disable: true,
        section: "",
      },
      { label: "Knowledge Retention", disable: true, section: "" },
      { label: "Communication & Compliance", disable: true, section: "" },
      { label: "Team Collaboration", disable: true, section: "" },
      {
        label: "Employee Development",
        disable: true,
        page: "",
        section: "#footer",
      },
      {
        label: "Digital Communities",
        disable: true,
        page: "",
        section: "#footer",
      },
      { label: "Tech Adoption", disable: true, page: "", section: "#footer" },
      {
        label: "Field Force Connect",
        disable: true,
        page: "",
        section: "#footer",
      },
      { label: "Lead Generation", disable: true, page: "", section: "#footer" },
      {
        label: "Customer Support",
        disable: true,
        page: "",
        section: "#footer",
      },
      { label: "Retention", disable: true, page: "", section: "#footer" },
      {
        label: "Upsell/Cross-sell",
        disable: true,
        page: "",
        section: "#footer",
      },
      {
        label: "Customer Education ",
        disable: true,
        page: "",
        section: "#footer",
      },
      { label: "User Engagement", disable: true, page: "", section: "#footer" },
      {
        label: "Employee Education ",
        disable: true,
        page: "",
        section: "#footer",
      },
      { label: "User Awareness", disable: true, page: "", section: "#footer" },
    ],
  },

  {
    heading: "Platform",
    type: "dropDown",
    items: [
      {
        label: "Core Video Platform",
        page: "/platform/",
        section: "#platform-core-video-platform",
      },
      {
        label: "Interactive & Personalized",
        page: "/platform/",
        section: "#platform-personalisation",
      },
      {
        label: "Embeddable Player & SDK ",
        page: "/platform/",
        section: "#platform-embeddable-player-sdk",
      },
      {
        label: "Video Analytics for BI",
        page: "/platform/",
        section: "#platform-Analytics",
      },
      {
        label: "Deep Search & Discovery",
        page: "/platform/",
        section: "#platform-search",
      },
      {
        label: "Content Creation Tools",
        page: "/platform/",
        section: "#platform-content-creation",
      },
      {
        label: "Live Broadcast",
        page: "/platform/",
        section: "#platform-live-stream",
      },
      {
        label: "Entrerprise Portal & CMS",
        page: "/platform/",
        section: "#platform-Enterprise-Portal-CMS",
      },
    ],
  },
  {
    heading: "AI @ KPOINT",
    type: "link",
    disable: false,
    page: "/ai-at-kpoint/",
    section: "#kai",
  },
  {
    heading: "Content Enablement",
    type: "link",
    disable: false,
    page: "/content-enablement/",
    section: "#content-enablement",
  },
  {
    heading: "About",
    type: "dropDown",
    items: [
      {
        label: "Mission, Vision & Values",
        page: "/about/",
        section: "#about-mission",
      },
      // { label: "Brand Assets", page: "/about/", section: "" },
      { label: "Our Story", page: "/about/", section: "#about-ourStory" },
      {
        label: "The Leadership Team",
        page: "/about/",
        section: "#about-leadershipTeam",
      },
    ],
  },
  {
    heading: "Resources",
    type: "dropDown",
    items: [
      { label: "Blogs", page: "/resources/", section: "blogs/" },
      { label: "Case Study", page: "/resources/", section: "casestudy/" },
      { label: "KPOINT NOW", page: "/resources/", section: "kpoint-now/" },
    ],
  },
  {
    heading: "Careers",
    type: "dropDown",
    items: [
      {
        label: "Our People",
        page: "/careers/",
        section: "#career-ourPeople",
      },
      {
        label: "Life at KPOINT",
        page: "/careers/",
        section: "#life-at-kpoint",
      },
      {
        label: "Current Job Openings",
        page: "/careers/",
        section: "#jobs-container",
      },
    ],
  },
  { heading: "Contact Us", type: "link", page: "/contact-us/", section: "" },
  {
    heading: "Head Office",
    type: "none",
    disable: true,
    items: [
      {
        label:
          "Office No. 201, 2nd Floor, S. R. Iriz Apartments, Baner - Pashan Link Road, Pune - 411021, Maharashtra, India",
        disable: true,
      },
      {
        label: "Ph. no. 020 4852 2626",
        disable: true,
      },
    ],
  },
  {
    heading: "Our Presence",
    disable: true,
    type: "address",
    items: [
      { label: "Pune", disable: true },
      { label: "Mumbai", disable: true },
      { label: "Delhi", disable: true },
      { label: "Bengaluru", disable: true },
    ],
  },
  // {
  //   heading: "Phone No.",
  //   disable: true,
  //   type: "address",
  //   items: [{ label: "020 4852 2626", disable: true }],
  // },
];

export default function Footer() {
  const { allStrapiFooterSection } = useStaticQuery(graphql`
    query footerSectionPage {
      allStrapiFooterSection {
        nodes {
          internal {
            content
          }
        }
      }
    }
  `);
  const { internal } = allStrapiFooterSection?.nodes[0] || {};
  const content = internal?.content ? JSON.parse(internal.content) : null;
  const { copyRight, companyLogo, socialLinks, privaryTerms } = content || {};
  const { url: logoUrl } = companyLogo || {};

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

  const handleDropdownToggle = (index) => {
    setActiveDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const renderLink = (item) => {
    if (item.disable) {
      return (
        <p
          style={{
            opacity: item.isAdress ? "0.5" : null,
            textDecoration: "none",
          }}
          className={`${styles.anchorLink} ${styles.disabledAnchorLink}`}
        >
          {item.label}
        </p>
      );
    }

    if (item.label === "FAB") {
      return (
        <a
          href="https://www.getfab.app/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.anchorLink}
        >
          {item.label}
        </a>
      );
    }

    return (
      <AnchorLink
        to={`${item.page || ""}${item.section}`}
        title={item.section}
        className={styles.anchorLink}
      >
        {item.label}
      </AnchorLink>
    );
  };

  const renderHeading = (section) => {
    if (section.disable) {
      return (
        <p className={`${styles.heading} ${styles.disabledHeading}`}>
          {section.heading}
        </p>
      );
    }
    return (
      <AnchorLink
        to={`${section.page || ""}${section.section}`}
        title={section.section}
        className={styles.heading}
      >
        {section.heading}
      </AnchorLink>
    );
  };

  const RedirectTo = ({ item }) => {
    return (
      <AnchorLink
        to={`${item.page || ""}${item.section}`}
        title={item.section}
        className={styles.nonClickableLink}
      >
        {item.heading}
      </AnchorLink>
    );
  };

  const DropdownSection = ({ section, isOpen, onToggle, index }) => {
    return (
      <>
        <div onClick={() => onToggle(index)} className={styles.dropDownTab}>
          <p className={styles.dropDownHeading}>{section.heading}</p>
          <RenderSvg
            IconName={isOpen ? "angle-up" : "angle-down"}
            IconType="light"
            style={{
              fill: "white",
              height: "24px",
              width: "24px",
            }}
          />
        </div>

        {isOpen && (
          <ul className={styles.dropDownListUl}>
            {section.items.map((link, linkIndex) => (
              <li
                key={linkIndex}
                className={`${styles.dropDownList} ${
                  link.disable ? styles.disabledLink : ""
                }`}
              >
                <a
                  className={` ${link.disable ? styles.disabledLink : ""}`}
                  href={`${link.page}${link.section}`}
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  const ConnectSection = ({ section }) => {
    return (
      <div className={styles.nonClickableContainer}>
        <p className={styles.nonClickable}>{section.heading}</p>
        <ul className={styles.dropDownListUl}>
          {section.items.map((item, index) => (
            <li key={index} className={styles.nonClickable}>
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const Locations = ({ item }) => {
    const items = item.items || [];
    return (
      <div className={styles.cityContainer}>
        {ScreenSize.isMobile && (
          <p className={styles.dropDownHeading}>{item.heading}</p>
        )}

        <div className={styles.citySubcontainer}>
          {items.map((city, cityIndex) => (
            <div className={styles.city} key={cityIndex}>
              {city.label}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <footer id="footer" className={styles.footer}>
      {ScreenSize.isDesktop() && (
        <div className={styles.footerDiv}>
          <Link to="/" className={styles.footerLogo}>
            <img src={`${baseUrl}${logoUrl}`} alt="Company Logo" />
          </Link>
          <div className={styles.linksContainer}>
            {footerData.map((group, groupIndex) => (
              <div
                key={groupIndex}
                className={`${styles.footerGroup} ${
                  styles[`footerGroup${groupIndex}`]
                }`}
              >
                {group.sections.map((section) => (
                  <div key={section.heading} className={styles.footerSection}>
                    {section.heading === "Our Presence" ? (
                      <></>
                    ) : (
                      renderHeading(section)
                    )}

                    {section.heading === "Our Presence" ? (
                      <Locations item={section} />
                    ) : (
                      <ul className={styles.ourPresenceList}>
                        {section?.items?.map((item, index) => (
                          <li key={index}>{renderLink(item)}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.bottomContent}>
            <p className={styles.copyRight}>{copyRight}</p>
            <div className={styles.group}>
              <ul className={styles.privacyPolicy}>
                {privaryTerms &&
                  privaryTerms.map((data, index) => {
                    const { label, openInNewTa } = data;
                    return (
                      <li key={index}>
                        <Link
                          className="body-secondary-sm"
                          style={{ textTransform: "capitalize" }}
                          target={openInNewTa ? "_blank" : "_self"}
                          to={window.location.origin + "/" + label}
                        >
                          {label === "privacy-policy"
                            ? "Privacy Policy"
                            : label === "terms-of-use"
                            ? "Terms & Conditions"
                            : label}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
              <div className={styles.footerLinksIcons}>
                {socialLinks?.map((e, i) => {
                  const { url, label } = e;
                  return (
                    <a key={i} href={url} target="_blank" rel="noreferrer">
                      <RenderSvg
                        IconName={label}
                        IconType="brands"
                        style={{ height: "16px" }}
                        fill="white"
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {ScreenSize.isTablet() ||
        (ScreenSize.isMobile() && (
          <div className={styles.footerDiv}>
            <Link to="/" className={styles.footerLogo}>
              <img src={`${baseUrl}${logoUrl}`} alt="Company Logo" />
            </Link>

            {mobileFooterData.map((section, sectionIndex) => (
              <>
                {section.type === "dropDown" && (
                  <DropdownSection
                    key={sectionIndex}
                    section={section}
                    isOpen={activeDropdownIndex === sectionIndex}
                    onToggle={handleDropdownToggle}
                    index={sectionIndex}
                  />
                )}
                {section.type === "link" && (
                  <RedirectTo key={sectionIndex} item={section} />
                )}
                {section.type === "none" && (
                  <ConnectSection key={sectionIndex} section={section} />
                )}
                {section.type === "address" && (
                  <Locations key={sectionIndex} item={section} />
                )}
              </>
            ))}

            <div className={styles.bottomContent}>
              <p className={styles.copyRight}>{copyRight}</p>
              <div className={styles.group}>
                <ul className={styles.privacyPolicy}>
                  {privaryTerms &&
                    privaryTerms.map((data, index) => {
                      const { label, openInNewTa } = data;
                      return (
                        <li key={index}>
                          <Link
                            className="body-secondary-sm"
                            style={{ textTransform: "capitalize" }}
                            target={openInNewTa ? "_blank" : "_self"}
                            to={window.location.origin + "/" + label}
                          >
                            {label === "privacy-policy"
                              ? "Privacy Policy"
                              : label === "terms-of-use"
                              ? "Terms & Conditions"
                              : label}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                <div className={styles.footerLinksIcons}>
                  {socialLinks?.map((e, i) => {
                    const { url, label } = e;
                    return (
                      <a key={i} href={url} target="_blank" rel="noreferrer">
                        <RenderSvg
                          IconName={label}
                          IconType="brands"
                          style={{ height: "16px" }}
                          fill="white"
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ))}
    </footer>
  );
}
